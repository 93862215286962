<template>
  <main class="page-users page-new-user">
    <PageTitleContainer>
      <PageTitle removeMargin>Nuovo operatore</PageTitle>

      <div class="text-right mb-3">
          <router-link class="btn btn-outline-secondary" :to="{ name: 'users.list' }">Indietro</router-link>
      </div>
    </PageTitleContainer>

    <div class="container">
      <UserForm :feedback.sync="feedback" @submitForm="submit" />
    </div>
  </main>
</template>

<script>

export default {
  name: 'user-new',
  components: {
    PageTitle: () => import('../../components/Layout/PageTitle'),
    PageTitleContainer: () => import('../../components/Layout/PageTitleContainer'),
    UserForm: () => import('../../components/Form/UserForm'),
  },
  data () {
    return {
      feedback: null,
    };
  },
  methods: {
    submit (userForm) {
      const user = { ...userForm };

      this.$api.createOperator(user)
        .then(res => {
          this.feedback = true;
          this.$router.push({ name: 'users.show', params: { id: res.data.id } });
        })
        .catch(err => {
          this.feedback = false;
          this.$log.error(err);
        })
      ;
    },
  },
};

</script>
